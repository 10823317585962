import React, { useState, useEffect, useCallback } from 'react';
import PropTypes 								   from 'prop-types';
import Flux 									   from '../../../flux/Flux';
import Translations 							   from '../../../utils/Translations';
import { VXPay, VXPayAction } 					   from '../../../utils/VXPay';

const Pin = (props) => {

	const { actorId, initialIsFavoriteOfGuest, onClickCallback, fontSize } = props;

	const [isFavoriteOfGuest, setIsFavoriteOfGuest] = useState(initialIsFavoriteOfGuest);

	const onActorAdded = useCallback((id) => {
		if (id === actorId) {
			setIsFavoriteOfGuest(true);
		}
	},[actorId]);

	const onActorRemoved = useCallback((id) => {
		if (id === actorId) {
			setIsFavoriteOfGuest(false);
		}
	},[actorId]);

	const togglePin = useCallback(() => {
		if (isFavoriteOfGuest) {
			Flux.Favorite.unpinActor(actorId);
		} else {
			Flux.Favorite.pinActor(actorId);
		}
		setIsFavoriteOfGuest((prev) => !prev);
	}, [isFavoriteOfGuest, actorId]);

	const onButtonClicked = useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();
			if (!Flux.Guest.isLoggedIn()) {
				const action = new VXPayAction(Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_ACTOR, {
					targetId: actorId,
				});
				VXPay.openSignupOrLogin({ host: actorId }, action);
			} else if (typeof onClickCallback === 'function') {
				onClickCallback();
			} else {
				togglePin();
			}
		},
		[actorId, onClickCallback, togglePin]
	);

	useEffect(() => {
		Flux.Favorite.addActorPinnedListener(onActorAdded);
		Flux.Favorite.addActorUnpinnedListener(onActorRemoved);

		return () => {
			Flux.Favorite.removeActorPinnedListener(onActorAdded);
			Flux.Favorite.removeActorUnpinnedListener(onActorRemoved);
		};
	}, [onActorAdded, onActorRemoved]);

	if (!actorId) {
		return null;
	}

	let classes = isFavoriteOfGuest ? 'icon -icon-heart-full' : 'icon -icon-heart-line';
	const title = isFavoriteOfGuest
		? Translations.get('favPinRemoveFromFav')
		: Translations.get('favPinAddToFav');

	if (fontSize) {
		classes += ` ${fontSize}`;
	}

    const label = <i data-tooltip-content={title} className={classes} data-tooltip-id="tooltip"/>;

	return (
		<span
			className = "favorite-actor-pin h-color-highlight h-cursor-pointer -txtsize"
			onClick   = {onButtonClicked}
		>
			{label}
		</span>
	);
};

Pin.propTypes = {
	actorId:                  PropTypes.number.isRequired,
	initialIsFavoriteOfGuest: PropTypes.bool.isRequired,
	onClickCallback:          PropTypes.func,
	fontSize:                 PropTypes.string,
};

export default Pin;
